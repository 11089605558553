import React from "react"
import { LocaleStoreContext } from "../components/LocaleStore"
import { Link } from "gatsby"
import styled from "styled-components"

import { FormattedMessage } from "react-intl"
import Card from "../components/Card"
import cardImage2 from "../../static/assets/map/india.svg"
import Layout from "../components/layout"
import Area from "../components/structural/Area"

import rotlogo from "../../static/assets/partners/rotary.svg"
import triblogo from "../../static/assets/partners/tribhuvandas.svg"
import unipadslogo from "../../static/assets/partners/unipads.svg"

import { useStaticQuery, graphql } from "gatsby"

const video = (locale) => {
  const url =
    locale === "de"
      ? "https://www.youtube.com/embed/sT_ZdsK91v8?controls=0"
      : "https://www.youtube.com/embed/o9IrZnjcLOw?controls=0"

  const title = locale === "de" ? "Wer ist Kanya?" : "Who is Kanya?"
  return (
    <iframe
      width="560"
      src={url}
      srcDoc={
        "<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=" +
        url +
        "?autoplay=1><img src=https://img.youtube.com/vi/sT_ZdsK91v8/hqdefault.jpg alt='" +
        title +
        "'><span>▶</span></a>"
      }
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    ></iframe>
  )
}

const PartnersArea = styled(Area)`
  .logo-container {
    display: flex;
    justify-content: space-evenly;
    opacity: 0.5;

    img {
      height: 120px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      img {
        margin-top: 32px;
      }
    }
  }
`

const Container = styled.div`
  .what-wrap {
    text-align: center;
  }

  .what-wrap p {
    max-width: 700px;
    margin: auto;
  }

  .video-wrap {
    text-align: center;
  }

  .video-wrap iframe {
    width: 60%;
    height: 450px;
  }

  .card-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 64px !important;
  }

  .card-wrapper p {
    font-size: 1em;
  }

  @media only screen and (max-width: 1150px) {
    .video-wrap iframe {
      width: 80%;
      height: 400px;
    }
  }

  @media only screen and (max-width: 800px) {
    .video-wrap iframe {
      width: 100%;
      height: 300px;
    }

    .what-wrap p {
      padding: 32px 0 0 0px;
      max-width: 700px;
    }
  }

  @media only screen and (max-width: 430px) {
    .video-wrap iframe {
      height: 200px;
    }
  }
`

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      medigrad: file(name: { eq: "medigrad" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      educatecardgradient: file(name: { eq: "educatecardgradient" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div>
      <Layout>
        <Container>
          <Area>
            <div className="video-wrap">
              <FormattedMessage id="Home.videosection.heading" tagName="h1" />
              <LocaleStoreContext.Consumer>
                {(context) => video(context.locale)}
              </LocaleStoreContext.Consumer>
            </div>
          </Area>
          <Area white className="what-wrap">
            <FormattedMessage id="Home.section1.heading" tagName="h1" />
            <FormattedMessage id="Home.section1.p" tagName="p" />
          </Area>
          <Area white className="what-wrap">
            <FormattedMessage id="Home.section2.heading" tagName="h1" />
            <FormattedMessage id="Home.section2.p" tagName="p" />
          </Area>
          <Area className="card-wrapper">
            <Card
              title={<FormattedMessage id="Card1.heading" />}
              subtext={<FormattedMessage id="Card1.subtext" />}
              img={data.medigrad.childImageSharp.fluid}
            >
              <Link to="/fundraising">
                <FormattedMessage id="Card.btn" />
              </Link>
            </Card>
            <Card
              title={<FormattedMessage id="Card2.heading" />}
              subtext={<FormattedMessage id="Card2.subtext" />}
              img={cardImage2}
              svg
              id="projects"
            >
              <Link to="/schools">
                <FormattedMessage id="Card.btn" />
              </Link>
            </Card>
            <Card
              title={<FormattedMessage id="Card3.heading" />}
              subtext={<FormattedMessage id="Card3.subtext" />}
              img={data.educatecardgradient.childImageSharp.fluid}
            >
              <Link to="/education">
                <FormattedMessage id="Card.btn" />
              </Link>
            </Card>
          </Area>
          <PartnersArea white className="py-8 text-center">
            <FormattedMessage id="Home.section3.heading" tagName="h1" />
            <div className="logo-container py-8">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://rid3060.rotaryindia.org/ClubFinderDetail.aspx?grp=964"
              >
                <img src={rotlogo} alt="rotary logo" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://tribhuvandasfoundation.com/"
              >
                <img src={triblogo} alt="tribhuvandas logo" />
              </a>
              <a target="_blank" rel="noreferrer" href="https://unipads.in/">
                <img src={unipadslogo} alt="unipads logo" />
              </a>
            </div>
          </PartnersArea>
        </Container>
      </Layout>
    </div>
  )
}
