import React from "react"

import styled from "styled-components"
import Img from "gatsby-image"

const Root = styled.div`
  border-radius: 10px;
  width: 400px;
  height: 600px;
  text-align: center;
  margin-bottom: 64px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  p {
    line-height: 20px;
    min-height: 90px;
    z-index: 1;
  }

  h2 {
    z-index: 1;
  }

  .image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: 400px;
    position: absolute;
    z-index: 0;
    bottom: 0;
  }

  a {
    margin-top: 16px;
    border: 1px solid #a2a2a2;
    transition-duration: 0.3s;
    border-radius: 50px;
    width: 160px;
    padding: 8px 8px 8px;
    text-decoration: none;
    cursor: pointer;
    background-color: #fff;
    z-index: 1;
  }
  a:hover {
    background-color: #a2a2a2 !important;
    color: white;
  }
`

function Card(props) {
  const image = props.svg ? (
    <img src={props.img} alt="card"></img>
  ) : (
    <Img fluid={props.img} alt="card" className={"image-wrapper"}></Img>
  )
  return (
    <Root id={props.id}>
      <h2>{props.title}</h2>
      <p className="mx-1 pa-0 my-0">{props.subtext}</p>
      {props.children}
      {image}
    </Root>
  )
}

export default Card
