import React from "react"
import "../css/app.css"
import { IntlProvider } from "react-intl"
import CookieConsent from "./CookieConsent"
import { Helmet } from "react-helmet"

import { LocaleStoreContext } from "./LocaleStore"
import Header from "./Header"
import Footer from "./Footer"

import localeData from "../../static/i18n.json"

const i18nMessage = localeData

export default class Layout extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Kanya's Chance</title>
          <meta
            name="description"
            content="Kanya's Chance e.V. is a Berlin-based non-profit organization founded by Berlin students working to improve access to menstrual hygiene products for women in developing countries."
          />
          <script>
            {`
            var gaProperty = 'UA-139055971-1';
            var disableStr = 'ga-disable-' + gaProperty;
            if (document.cookie.indexOf(disableStr + '=true') > -1) {
              window[disableStr] = true;
            }
            `}
          </script>
          <script id="mcjs">
            {`
          
          !function(c,h,i,m,p)
          {
            ((m = c.createElement(h)),
            (p = c.getElementsByTagName(h)[0]),
            (m.async = 1),
            (m.src = i),
            p.parentNode.insertBefore(m, p))
          }
          (document,"script","https://chimpstatic.com/mcjs-connected/js/users/409674a29373316da23f70a4b/679005caf55fe58feb0c260b0.js");
       
          
          `}
          </script>
        </Helmet>
        <LocaleStoreContext.Consumer>
          {context => (
            <IntlProvider
              locale={context.locale}
              messages={i18nMessage[context.locale]}
            >
              <div>
                <Header fixed />
                <div>{this.props.children}</div>
                <Footer />
                <CookieConsent />
              </div>
            </IntlProvider>
          )}
        </LocaleStoreContext.Consumer>
      </div>
    )
  }
}
